@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Playball&display=swap');

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Montserrat", sans-serif;
}

body {
    overflow-x: hidden;
}

.marquee-link {
  color: white;
  /* White text */
  text-decoration: underline;
  /* Always underlined */
  transition: color 0.2s ease-in-out;
}

.marquee-link:hover {
  color: black;
  /* Turn black on hover */
}