/* swiperStyles.css */

/* Adjust the slides' opacity and scaling */
.swiper-slide {
  /* opacity: 0.5; */
  transform: scale(0.85);
  /* transition: opacity 300ms ease, transform 300ms ease; */
}

.swiper-slide-active {
  /* opacity: 1; */
  transform: scale(1);
  /* transition: opacity 300ms ease, transform 300ms ease; */
}
